import React from 'react';
import styled from 'styled-components';

import Home from '../components/templates/Home';
import { Container, Row, Col, Box, Title, Link } from '../components/atoms';
import Card from '../components/molecules/Card';
import Heartbeat from '../components/molecules/Heartbeat';

import iconDP from '../assets/img/icons/dp.svg';
import iconStyle from '../assets/img/icons/style.svg';
import iconCV from '../assets/img/icons/core-values.svg';
// import iconComp from '../assets/img/icons/components.svg';
import logo from '../assets/img/logo.svg';
import bg from '../assets/img/footer_illustration.svg';

const Content = styled(Box)`
  background: url(${bg}) no-repeat 50% calc(100% + 400px);
`;

const IndexPage = () => (
  <Home>
    <Box mb={80}>
      <Box p="40px 40px 36px">
        <Link href="https://heartbeat.ua">
          <img src={logo} alt="" />
        </Link>
      </Box>
    </Box>
    <Content pb={400}>
      <Container>
        <Title as="h1" size="h1" mb={88}>
          .pulse
        </Title>
        <Title as="h2" size="h4" mb={60}>
          Helping us build great products for our customers
        </Title>
        <Card
          to="/introduction"
          title="Introduction"
          text="We believe that shared design principles have the power to do incredible things for digital product teams."
          name="intro"
          mb="72px"
          p="88px"
        />
        <Row gap={72}>
          <Col mb={72}>
            <Card
              to="/design-tips"
              title="Design tips"
              text="Rules and best practices <br /> behind our work."
              icon={iconDP}
            />
          </Col>
          <Col width={[1 / 2.5]} mb={72}>
            <Card
              to="/principles"
              title="Principles"
              text="Colors, spacing, fonts and other fundamental things."
              icon={iconStyle}
            />
          </Col>
        </Row>
        <Row gap={72}>
          <Col>
            <Card
              to="/core-values"
              title="Core Values"
              text="4 principles we use to hire, motivate, reward and align our team."
              icon={iconCV}
            />
          </Col>
          {/* <Col width={[1 / 2.5]}>
            <Card
              to="/components/box"
              title="Components"
              text="Library of React components we use. Inspired by Atomic methodology."
              icon={iconComp}
            />
          </Col> */}
        </Row>
        <Heartbeat />
      </Container>
    </Content>
  </Home>
);

export default IndexPage;
