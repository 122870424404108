import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Title, Link, Text, Flex } from '../../atoms';

import { styledSpaces } from '../../../utils';

const StyledCard = styled(Link)`
  display: block;
  height: 100%;
  transition: box-shadow 0.1s ease;
  &:hover {
    box-shadow: 0 0 0 6px;
  }
`;

const Card = ({ to, icon, title, text, name, border, ...rest }) => {
  return (
    <StyledCard to={to || '/'} name={name} border={border} {...styledSpaces(rest)}>
      {icon && (
        <Flex display="flex" alignItems="center" height={116} mb="xl">
          <div>
            <img src={icon} alt="" />
          </div>
        </Flex>
      )}
      <Title as="h3" size="h4" mb="s">
        {title}
      </Title>
      <Text
        m={0}
        maxWidth={420}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </StyledCard>
  );
};

Card.propTypes = {
  border: PropTypes.string,
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  to: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
};

Card.defaultProps = {
  border: '2px solid',
  p: 56,
};

export default Card;
