import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from '../../../utils';
import theme from '../../../theme';
import Head from '../../helpers/Head';

import '../../../assets/css/normalize.css';
import '../../../assets/css/base.css';

const Wrapper = styled.div`
  font-family: ${themeGet('fonts.primary')}, sans-serif;
  color: ${themeGet('colors.black')};
  font-size: ${themeGet('fontSizes.l')}px;
  line-height: ${themeGet('lineHeights.l')};
`;

const Home = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Head />
      <Wrapper>{children}</Wrapper>
    </ThemeProvider>
  );
};

Home.propTypes = {
  children: PropTypes.node,
};

export default Home;
